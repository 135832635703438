// boilerplate generated 2024-05-19 08:43:06
import Vue from 'vue';

import Vuex from 'vuex';
import location from '@/vuex/location.js';
import session from '@/vuex/session.js';
import database from '@/vuex/database.js';
import systemd from '@/vuex/systemd.js';
import databases from '@/vuex/databases.js';
import server from '@/vuex/server.js';
import socket from '@/vuex/socket.js';
import controlpanels from '@/vuex/controlpanels.js';
import indicators from '@/vuex/indicators.js';
import diagramdata from '@/vuex/diagramdata.js';
import diagrams from '@/vuex/diagrams.js';
import queries from '@/vuex/queries.js';
import regulations from '@/vuex/regulations.js';
import sensors from '@/vuex/sensors.js';

Vue.use(Vuex)
export default new Vuex.Store({
strict: true,
  modules: {
	location,
	session,
	database,
	systemd,
	databases,
	server,
	socket,
	controlpanels,
	indicators,
	diagramdata,
	diagrams,
	queries,
	regulations,
	sensors,
	}
})
